import { Row, Space } from 'antd';
import { get } from 'lodash';
import React, { useMemo, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { handleApiErrors } from '../../../api/axiosInstance';
import FAVRValuesAPI from '../../../api/favr-values';
import { PERIOD_ANOMALIES_API } from '../../../api/periodAnomalies';
import AnomaliesMileageAdjustmentsTable from '../../../components/Table/AnomaliesMileageAdjustmentsTable';
import Toast from '../../../components/Toast';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import useTablePagination from '../../../hooks/useTablePagination';
import useTableSort from '../../../hooks/useTableSort';
import { hasCompanyAdminRole } from '../../../utils/roles';
import {
  selectStoreCurrentAuthUser,
  selectStoreCurrentCompany,
} from '../../../utils/storeSelectors';
import AnomalyPagesFilterHeader from '../../anomalies-dashboard/components/AnomalyPagesFilterHeader';

const FavrMileageAdjustmentReport = props => {
  const { t } = props;

  const currentCompany = useSelector(selectStoreCurrentCompany);
  const authUser = useSelector(selectStoreCurrentAuthUser);

  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [isAdjustingRates, setIsAdjustingRates] = useState(false);

  const {
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
    handleTotalUpdate,
  } = useTablePagination();

  const { tableSort, handleTableSort } = useTableSort({ variancePercentage: -1 });

  const anomaliesParams = useMemo(() => {
    setSelectedUserIds([]);
    return {
      companyId: currentCompany._id,
      adjustmentAvailable: true,
      type: 'mileage-adjustments',
    };
  }, [currentCompany]);

  const mileageAdjustmentAnomaliesQuery = useQuery({
    queryKey: [
      'getPeriodAnomalies',
      anomaliesParams.type,
      anomaliesParams.companyId,
      paginationConfig.current,
      paginationConfig.pageSize,
      JSON.stringify({
        ...tableSort,
        'user.firstName': 1,
        'user.lastName': 1,
      }),
    ],
    queryFn: () =>
      PERIOD_ANOMALIES_API.getPeriodAnomalies(
        {
          sort: {
            ...tableSort,
            'user.firstName': 1,
            'user.lastName': 1,
          },
          ...anomaliesParams,
        },
        paginationConfig.current,
        paginationConfig.pageSize,
      ),
    onSuccess: ({ totalCount }) => handleTotalUpdate(totalCount),
  });

  const exportMileageAdjustmentsAnomaliesMutation = useMutation(() =>
    PERIOD_ANOMALIES_API.exportPeriodAnomalies(anomaliesParams),
  );

  const requestNewRateMutation = useMutation(
    anomalyId => new FAVRValuesAPI().requestNewRate(anomalyId),
    {
      onSuccess: () => {
        mileageAdjustmentAnomaliesQuery.refetch();
        Toast({
          type: 'open',
          message: t('anomalies:rateRequestSuccess'),
        });
      },
      onError: error => {
        handleApiErrors(error.response, () => {
          Toast({
            type: 'error',
            message: t('anomalies:rateRequestError'),
          });
        });
      },
    },
  );

  const handleRateConfirmation = useMutation(
    () => new FAVRValuesAPI().applyAdjustedRateValues(currentCompany._id, selectedUserIds),
    {
      onError: error => handleApiErrors(error.response),
      onSuccess: () => {
        setIsAdjustingRates(false);
        setSelectedUserIds([]);
        mileageAdjustmentAnomaliesQuery.refetch();
      },
    },
  );

  const rowSelection = useMemo(() => {
    if (hasCompanyAdminRole(authUser) && isAdjustingRates) {
      return {
        selectedRowKeys: selectedUserIds,
        onChange: userIds => {
          setSelectedUserIds(userIds);
        },
      };
    }

    return null;
  }, [authUser, selectedUserIds, isAdjustingRates]);

  useDidUpdateEffect(() => {
    if (isAdjustingRates) {
      setSelectedUserIds([]);
    }
  }, [isAdjustingRates]);

  return (
    <div>
      <Space direction="vertical" size="large">
        <AnomalyPagesFilterHeader
          t={t}
          isExportingData={exportMileageAdjustmentsAnomaliesMutation.isLoading}
          onDataExport={exportMileageAdjustmentsAnomaliesMutation.mutateAsync}
          isAdjustingRates={isAdjustingRates}
          isConfirmingRates={handleRateConfirmation.isLoading}
          onAdjustRates={hasCompanyAdminRole(authUser) ? () => setIsAdjustingRates(true) : null}
          onConfirmRates={hasCompanyAdminRole(authUser) ? handleRateConfirmation.mutateAsync : null}
        />

        <Row>
          <AnomaliesMileageAdjustmentsTable
            t={t}
            asyncSort
            hiddenColumns={!hasCompanyAdminRole(authUser) ? ['actions'] : []}
            rowKey={record => get(record, 'user._id')}
            rowSelection={rowSelection}
            loading={mileageAdjustmentAnomaliesQuery.isFetching || requestNewRateMutation.isLoading}
            dataSource={get(mileageAdjustmentAnomaliesQuery, 'data.documents', [])}
            onRequestNewRate={requestNewRateMutation.mutateAsync}
            onChange={({ current }, filters, sorters) => {
              handlePageChange(current);
              handleTableSort(sorters?.columnKey, sorters?.order);
            }}
            pagination={{
              pageSize: paginationConfig.pageSize,
              total: paginationConfig.total,
              current: paginationConfig.current,
              onShowSizeChange: handlePageSizeChange,
            }}
          />
        </Row>
      </Space>
    </div>
  );
};

export default withNamespaces(['default', 'anomalies'], {
  wait: false,
})(FavrMileageAdjustmentReport);
