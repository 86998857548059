import { Col, Collapse, Row } from 'antd';
import { get } from 'lodash';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';

import OutOfComplianceAlert from '../../../components/Alert/OutOfComplianceAlert';
import Box from '../../../components/Box';
import HorizontalLineDivider from '../../../components/HorizontalLineDivider';
// import HorizontalLineDivider from '../../../components/HorizontalLineDivider';
import BusinessVehicleHistoryModal from '../../../components/Modal/BusinessVehicleHistoryModal';
import FavrValuesHistoryModal from '../../../components/Modal/FavrValuesHistoryModal';
import SpaceSpinner from '../../../components/SpaceSpinner';
import Text from '../../../components/Text';
import LinkText from '../../../components/Text/LinkText';
import { EXTERNAL_LINKS, IMAGES } from '../../../enum';
import useModal from '../../../hooks/useModal';
import useVehicleCompliance from '../../../hooks/useVehicleCompliance';
import { formatPageTitle } from '../../../utils/common';
import CompanyStandardAutomobileSection from './CompanyStandardAutomobileSection';
import UserFavrInformationDetailsSection from './UserFavrInformationDetailsSection';
// import MonthlyComplianceStatusSection from './MonthlyComplianceStatusSection';
// import PaymentHistorySection from './PaymentHistorySection';
// import TaxStatementsSection from './TaxStatementsSection';

const PageHelmet = () => (
  <Helmet>
    <title>{formatPageTitle('FAVR Information')}</title>
  </Helmet>
);

const UserFavrInformationView = props => {
  const { t, userDetails, isLoading, favrInformation, userComplianceDetails, userRate } = props;

  const isOutOfCompliance = useVehicleCompliance(userDetails?._id);
  const [isVehicleHistoryModalVisible, setIsVehicleHistoryModalVisible] = useState(false);

  const toggleVehicleHistoryModal = () => setIsVehicleHistoryModalVisible(visible => !visible);

  const [isFavrHistoryModalVisible, openFavrHistoryModal, closeFavrHistoryModal] = useModal();

  if (isLoading) {
    return (
      <Box>
        <PageHelmet />
        <SpaceSpinner />
      </Box>
    );
  }

  return (
    <Box>
      <PageHelmet />

      {isOutOfCompliance && (
        <OutOfComplianceAlert favrHelpText userComplianceDetails={userComplianceDetails} />
      )}

      <Text variant="h3">{t('favrProgramInformation')}</Text>

      <br />

      <CompanyStandardAutomobileSection
        t={t}
        favrInformation={favrInformation}
        onViewFavrHistory={openFavrHistoryModal}
        onViewVehicleHistory={toggleVehicleHistoryModal}
        userRate={userRate}
      />

      {(favrInformation?.fixedRateDetails || favrInformation?.variableRateDetails) && (
        <>
          <HorizontalLineDivider marginBottom={3} />

          <Collapse
            ghost
            expandIcon={({ isActive }) => (
              <img
                src={IMAGES.GREEN_CHEVRON_DOWN_ICON}
                alt="expand icon"
                style={{ transform: `rotate(${isActive ? 0 : 180}deg)` }}
              />
            )}
          >
            <Collapse.Panel header={<Text variant="h5">{t('favrRateDetails')}</Text>}>
              <UserFavrInformationDetailsSection
                t={t}
                favrInformation={favrInformation}
                isStandardVehicleElectric={/electric/gi.test(
                  get(favrInformation, 'standardVehicle.fuel', ''),
                )}
              />
              <br />

              <Row justify="end">
                <Col>
                  <Text>
                    {t('questionsAboutYourRate')}{' '}
                    <LinkText
                      rel="noopener noreferrer"
                      target="_blank"
                      href={EXTERNAL_LINKS.FRESHDESK_NEW_TICKET}
                      renderAs="a"
                    >
                      {t('sendUsSupportTicket')}
                    </LinkText>
                  </Text>
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>
        </>
      )}

      {/* <MonthlyComplianceStatusSection t={t} /> */}

      {/* <HorizontalLineDivider /> */}

      {/* <PaymentHistorySection /> */}

      {/* <HorizontalLineDivider /> */}

      {/* <TaxStatementsSection t={t} /> */}

      <BusinessVehicleHistoryModal
        t={t}
        visible={isVehicleHistoryModalVisible}
        onCancel={toggleVehicleHistoryModal}
      />

      <FavrValuesHistoryModal
        t={t}
        isStandardVehicleElectric={/electric/gi.test(
          get(favrInformation, 'standardVehicle.fuel', ''),
        )}
        userId={userDetails?._id}
        visible={isFavrHistoryModalVisible}
        onCancel={closeFavrHistoryModal}
      />
    </Box>
  );
};

export default withNamespaces()(UserFavrInformationView);
