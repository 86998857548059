import { Row } from 'antd';
import React from 'react';

import { formatNumberWithCurrency } from '../../../utils/numbers';
// import BottomBorderTextRows from '../../BottomBorderTextRows'
import Text from '../../Text';

const FavrVariableRateSummary = props => {
  const { t, currency, variableRate, distanceUnit } = props;

  return (
    <div>
      <Row>
        <Text size="xl">
          <Text size="xl" variant="strong">
            {formatNumberWithCurrency(variableRate || 0, currency || 'USD')}
          </Text>
          {' / '}
          {t(distanceUnit)}
        </Text>
      </Row>

      <br />

      {/* <BottomBorderTextRows
        rowContent={[
          {
            label: t('fuel'),
            value: formatCurrencyToCompanyLocale('0.03'),
          },
          {
            label: t('maintenanceTires'),
            value: formatCurrencyToCompanyLocale('0.08'),
          },
        ]}
      /> */}
    </div>
  );
};

FavrVariableRateSummary.defaultProps = {
  distanceUnit: 'mile',
};

export default FavrVariableRateSummary;
