import { first, get } from 'lodash';
import moment from 'moment';

import Toast from '../components/Toast';
import i18n from '../i18n';
import { logoutUser } from '../utils/auth';
import { formatQueryRange, getUserFullName } from '../utils/common';
import { cleanQueryParams } from '../utils/queryParams';
import {
  handleApiCalls,
  handleApiFileDownloads,
  performApiCallIfCompanySubIsActive,
} from './axiosInstance';
import { COUNTRY_RATES_API } from './countryRates';

/**
 * Performs an API call to fetch the allowed timezones for a given company
 *
 * @return {Promise<any>} API Result
 */
export const fetchTimezones = async () => {
  const result = await handleApiCalls(
    'get',
    `${process.env.REACT_APP_HOST_API}company/getAllowedTimeZones`,
  );

  return result.data.data;
};

const parseCountryRules = (countryRate = {}, rules = []) => {
  if (Array.isArray(rules) && !!rules.length) {
    switch (rules[0].rateType) {
      case 'band':
        countryRate.defaultBands = [];

        rules.forEach(rule => {
          if (/mileage-band/gi.test(rule.ruleType)) {
            countryRate.defaultBands.push({
              bandStart: rule.mileageBandMin,
              bandEnd: rule.mileageBandMax === -1 ? 99999 : rule.mileageBandMax,
              bandRate: rule.unitRate,
            });
          }
        });

        break;
      default:
        countryRate.defaultRate = rules.length ? rules[0].unitRate : null;
        countryRate.unit = rules.length ? rules[0].unit : null;
        break;
    }
  }

  return countryRate;
};

/**
 * Fetches the active Rate for a given Country's government flat rate
 *
 * @param {string} countryCode  Country Code
 */
export const fetchActiveCountryRate = async countryCode => {
  const rates = await COUNTRY_RATES_API.fetchCountryRates({ countryCode, defaultRate: true });

  const data = first(rates.documents);

  const countryRate = {
    country: data.countryCode,
    name: data.name,
    currency: data.currency,
    effectiveDate: moment(new Date(data.effectiveStartDate)).format('YYYY-MM-DD'),
    status: data.status,
  };

  parseCountryRules(countryRate, data.rules);

  return countryRate;
};

export const fetchLatestCompanySubscription = async companyID => {
  const url = `${process.env.REACT_APP_HOST_API}company/${companyID}/subscriptions?range=[0, 1]`;
  let result = await handleApiCalls('get', url);

  return result.data;
};

export const getCompanyByID = async companyID => {
  try {
    let result = await handleApiCalls(
      'get',
      `${process.env.REACT_APP_HOST_API}company/${companyID}`,
    );
    return result.data.data;
  } catch (err) {
    logoutUser();
    Toast({
      type: 'error',
      message: i18n.t('getCompanyInfoError'),
    });
  }
};

export const getCompanyInfo = async userInfo => {
  let companyInfo = {};
  const companyId = userInfo.businessInfor.companies[0].companyID;

  if (companyId) {
    try {
      let result = await handleApiCalls(
        'get',
        `${process.env.REACT_APP_HOST_API}company/${companyId}`,
      );
      if (result.status === 200) {
        companyInfo = result.data.data;
      }
    } catch (err) {
      Toast({
        type: 'error',
        message: i18n.t('getCompanyInfoError'),
      });
    }
  }
  return companyInfo;
};

/**
 * Performs an API call to update the current onboarding step
 *
 * @param {string} companyID ID of Company
 * @param {number} regStatus Onboarding step number
 */
export const setCompanyRegistrationStatus = async (companyID, regStatus) => {
  const url = `${process.env.REACT_APP_HOST_API}company/${companyID}/update-registration-status`;

  const result = await handleApiCalls('patch', url, { regStatus });
  return result.data.data;
};

/**
 * Performs an API call to set a plan on the company for Free Trial
 *
 * @param {string} companyID ID of Company
 * @param {number} planType Plan number
 */
export const setCompanyPlan = async (companyID, planType) => {
  const url = `${process.env.REACT_APP_HOST_API}company/${companyID}/price-plan`;

  const result = await handleApiCalls('patch', url, { planType });
  return result.data.data;
};

/**
 * Performs an API call in order to fetch a Company's credit card info
 *
 * @param {string} companyID ID of Company
 */
export const fetchCompanyPaymentMethods = async companyID => {};

/**
 * Performs an API call to soft delete a company
 *
 * @param {string} companyID ID of Company to delete
 * @param {string} text Delete confirmation text
 */
export const deleteCompany = async (companyID, text) => {
  const url = `${process.env.REACT_APP_HOST_API}company/${companyID}`;
  const result = await handleApiCalls('delete', url, { params: { text } });

  return result.data.data;
};

/**
 * Performs an API call to update a company's mileage purpose
 *
 * @param {string} companyID ID of Company to delete
 */
export const updateCompanyMileagePurpose = async (companyID, mileagePurpose) => {
  const url = `${process.env.REACT_APP_HOST_API}company/${companyID}/updateMileagePurpose`;

  const result = await handleApiCalls('patch', url, { mileagePurpose });

  return result.data.data;
};

export const fetchNewCompanyApiKey = async () => {
  const url = `${process.env.REACT_APP_HOST_API}company/api-key`;
  const result = await handleApiCalls('get', url);

  return result?.data;
};

export const fetchCompanyPayrollAudits = async (companyID, params = {}) => {
  const url = `${
    process.env.REACT_APP_HOST_API
  }company/${companyID}/payroll-audits?${cleanQueryParams(params)}`;
  const result = await handleApiCalls('get', url);

  return result?.data?.data;
};

export const updateIntegrationCredentials = async (companyID, data) => {
  const url = `${process.env.REACT_APP_HOST_API}company/${companyID}/integration-credentials`;
  const result = await performApiCallIfCompanySubIsActive('patch', url, { sftp: data });

  return result?.data?.sftp;
};

export const fetchCompanyUsersMobileSettings = async companyID => {
  const url = `${process.env.REACT_APP_HOST_API}company/${companyID}/mobile-settings`;
  const result = await handleApiCalls('get', url);

  return result.data.sort((a, b) => {
    const aFullName = getUserFullName(a);
    const bFullName = getUserFullName(b);

    return aFullName.toLowerCase().localeCompare(bFullName.toLowerCase());
  });
};

export const fetchCompanyInvoices = async (companyID, year) => {
  const url = `${process.env.REACT_APP_HOST_API}company/${companyID}/invoices?${cleanQueryParams({
    year,
  })}`;
  const result = await handleApiCalls('get', url);

  return result?.data;
};

export const COMPANY_API = Object.freeze({
  /**
   * Performs an API request to fetch the current active plan/subscription for a given company
   *
   * @param {string} companyID ID of Company
   */
  getActivePlan: async (companyID, onSuccess) => {
    let dataUrl = `${process.env.REACT_APP_HOST_API}company/${companyID}/active-subscription`;
    let result = await handleApiCalls('get', `${dataUrl}`);

    if (result.status === 200) {
      let resultData = result.data.data;

      if (!!Object.keys(resultData).length) {
        const plan = {
          ...resultData,
          subEndDate: result.data.endDate,
        };

        if (typeof onSuccess === 'function') {
          onSuccess(plan);
        }

        return plan;
      } else if (typeof onSuccess === 'function') {
        onSuccess();
      }
    }
  },
  /**
   * @param {string} companyID
   */
  getCompanyFavrComplianceUsers: async companyID => {
    const url = `${process.env.REACT_APP_HOST_API}company/${companyID}/favr-compliances/users`;
    const result = await handleApiCalls('get', url);
    return result.data;
  },
  /**
   * Fetches all users' FAVR compliance data for a company
   * @param {string} companyID - The ID of the company
   * @param {object} [filters={}] - Optional filters for the query
   * @param {string} [filters.searchTerm] - Search term to filter users
   * @param {string} [filters.userId] - Specific user ID to filter
   * @param {"all" | "valid" | "invalid"} [filters.status] - Compliance status filter
   * @param {string} [filters.startDate] - Start date for date range filter (format: YYYY-MM-DD)
   * @param {string} [filters.endDate] - End date for date range filter (format: YYYY-MM-DD)
   * @param {number} currentPage - Current page number for pagination
   * @param {number} pageSize - Number of items per page
   * @param {string} [sort] - Sorting criteria
   * @returns {Promise<{ documents: object[], totalCount: number }>} - Resolves to an object containing the compliance documents and total count
   */
  getAllUsersFavrComplianceData: async (companyID, filters = {}, currentPage, pageSize, sort) => {
    const range = formatQueryRange(currentPage, pageSize);
    const params = cleanQueryParams({ range, sort, ...filters });

    const url = `${process.env.REACT_APP_HOST_API}company/${companyID}/favr-compliances?${params}`;
    const result = await handleApiCalls('get', url);
    return result.data;
  },
  /**
   * Export all users FAVR compliance data
   * @param {string} companyID - The ID of the company
   * @param {object} [filters={}] - Optional filters for the export
   * @param {string} [filters.searchTerm] - Search term for filtering users
   * @param {string} [filters.userId] - Specific user ID to filter
   * @param {"all" | "valid" | "invalid"} [filters.status] - Status filter for compliance
   * @param {string} [filters.startDate] - Start date for date range filter (format: YYYY-MM-DD)
   * @param {string} [filters.endDate] - End date for date range filter (format: YYYY-MM-DD)
   * @param {number} currentPage - Current page number for pagination
   * @param {number} pageSize - Number of items per page
   * @param {string} [sort] - Sorting criteria
   * @returns {Promise<void>} - Returns a promise that resolves when the file download is initiated
   */
  exportAllUsersFavrComplianceData: async (
    companyID,
    filters = {},
    currentPage,
    pageSize,
    sort,
  ) => {
    const range = formatQueryRange(currentPage, pageSize);
    const params = cleanQueryParams({ range, sort, ...filters });
    const url = `${process.env.REACT_APP_HOST_API}company/${companyID}/favr-compliances/export?${params}`;
    await handleApiFileDownloads('get', url);
  },
  /**
   * Get company users tax reports
   * @param {string} companyID
   * @param {number} year
   * @param {number} pageNumber
   * @param {number} pageSize
   * @param {string} sort
   * @returns { documents: object[], totalCount: number }
   */
  fetchCompanyTaxReport: async (companyID, year, pageNumber, pageSize, sort) => {
    const range = formatQueryRange(pageNumber, pageSize);
    const queryParams = cleanQueryParams({ range, year, sort });
    const url = `${process.env.REACT_APP_HOST_API}company/${companyID}/users/tax-reports?${queryParams}`;
    const result = await handleApiCalls('get', url);
    return result.data;
  },
  /**
   * Export company users tax reports
   * @param {string} companyId
   * @param {number} year
   */
  exportAllCompanyUsersTaxReport: async (companyId, year, sort) => {
    const url = `${process.env.REACT_APP_HOST_API}company/users/tax-reports/export`;
    const result = await handleApiCalls('post', url, { companyId, year, sort });
    return result.data;
  },
  /**
   * Fetch all companies registered on Kliks
   * @param {object} params
   * @param {number} params.pageNumber
   * @param {number} params.pageSize
   * @param {string} params.searchTerm
   * @returns {{ totalCount: number, documents: object[] }}
   */
  getAllCompanies: async ({ pageNumber, pageSize, searchTerm }) => {
    const range = formatQueryRange(pageNumber, pageSize);
    const params = cleanQueryParams({ range, name: searchTerm, sort: JSON.stringify({ name: 1 }) });
    let url = `${process.env.REACT_APP_HOST_API}company?${params}`;
    const result = await handleApiCalls('get', url);

    return result.data;
  },

  /**
   * @typedef {Object} UserMobileAppSetting
   * @property {string} _id
   * @property {string} firstName
   * @property {string} lastName
   * @property {MobileAppSetting[]} mobileAppSettings
   */

  /**
   * Fetch mobile app settings of all users in a company
   * @param {string} companyId
   * @param {number} pageNumber
   * @param {number} pageSize
   * @returns {{ documents: UserMobileAppSetting[], totalCount: number }}
   */
  fetchCompanyUsersMobileAppSettings: async (companyId, pageNumber, pageSize) => {
    const range = formatQueryRange(pageNumber, pageSize);
    const queryParams = cleanQueryParams({ range });
    const result = await handleApiCalls(
      'get',
      `${process.env.REACT_APP_HOST_API}company/${companyId}/users/mobile-app-settings?${queryParams}`,
    );
    return result.data;
  },

  /**
   * @typedef {Object} UsersWithUnsubmittedTripsSummaryResponse
   * @property {object[]} users
   * @property {string} users._id
   * @property {string} users.email
   * @property {string} users.firstName
   * @property {string} users.lastName
   * @property {string[]} groups Array of group names
   * @property {number} totalTrips
   * @property {number} totalMileage
   * @property {number} totalReimbursement
   * @property {number} usersCount
   */

  /**
   * Get company users trips summary
   * @param {string} companyId
   * @param {string?} userId
   * @param {string?} groupId
   * @returns {UsersWithUnsubmittedTripsSummaryResponse}
   */
  fetchUsersWithUnsubmittedTripsSummary: async (companyId, userId, groupId) => {
    const queryParams = cleanQueryParams({ userId, groupId });
    const result = await handleApiCalls(
      'get',
      `${process.env.REACT_APP_HOST_API}company/${companyId}/users/trips/summary?${queryParams}`,
    );
    return result.data;
  },

  /**
   * @typedef {Object} UserAgregatedData
   * @property {object} user
   * @property {object} user.group
   * @property {string} user.group.name
   * @property {string} user._id
   * @property {string} user.firstName
   * @property {string} user.lastName
   * @property {string} user.status
   * @property {number} totalTrips
   * @property {number} totalMileage
   * @property {number} totalReimbursement
   * @property {string} oldestJourneyStartTs
   */

  /**
   * Fetch Users Agregated Data
   * @param {string} companyId
   * @param {string} groupId
   * @param {number} pageNumber
   * @param {number} pageSize
   * @param {string} sort
   * @returns {{ documents: UserAgregatedData[] }}
   */
  fetchUsersAgregatedData: async (companyId, groupId, sort) => {
    const queryParams = cleanQueryParams({ groupId, sort });
    const result = await handleApiCalls(
      'get',
      `${process.env.REACT_APP_HOST_API}company/${companyId}/users/trips/aggregated-data?${queryParams}`,
    );

    return result.data;
  },

  /**
   * Export Users Agregated Data
   * @param {string} companyId
   * @param {string} groupId
   * @param {number} pageNumber
   * @param {number} pageSize
   * @param {string} sort
   * @returns {File} CSV File
   */
  exportUsersAgregatedData: async (companyId, groupId, sort) => {
    await handleApiCalls(
      'post',
      `${process.env.REACT_APP_HOST_API}company/${companyId}/users/trips/export`,
      { groupId, sort },
    );
  },

  /**
   * Create reminder request
   * @param {"unsubmitted-trips"} type
   * @param {string[]} usersIds
   * @param {string} customText
   */
  createReminderRequest: async (type, usersIds, customText) => {
    await handleApiCalls('post', `${process.env.REACT_APP_HOST_API}company/users/reminder`, {
      usersIds,
      type,
      customText,
    });
  },

  fetchUserCountByStatus: async companyId => {
    const result = await handleApiCalls(
      'get',
      `${process.env.REACT_APP_HOST_API}company/${companyId}/users/by-status`,
    );

    return result.data;
  },

  /**
   * Fetch a list of all users within a Company with an active FAVR Rate
   * @param {string} companyId
   * @param {object} filters
   * @param {"active"[]} filters.status
   * @param {number} pageNumber
   * @param {number} pageSize
   * @param {string} sort
   */
  fetchCompanyFAVRvalues: async (companyId, filters, pageNumber, pageSize, sort) => {
    const queryParams = cleanQueryParams({
      ...(filters || {}),
      status: JSON.stringify(get(filters, 'status', [])),
      sort,
      range: formatQueryRange(pageNumber, pageSize),
    });
    const response = await handleApiCalls(
      'get',
      `${process.env.REACT_APP_HOST_API}company/${companyId}/favr-values?${queryParams}`,
    );

    return response.data;
  },

  /**
   * Export list of all users within a Company with an active FAVR Rate as CSV
   * @param {string} companyId
   * @param {object} filters
   * @param {"active"[]} filters.status
   * @param {string} sort
   */
  exportCompanyFAVRvalues: async (companyId, filters, sort) => {
    const queryParams = cleanQueryParams({
      ...(filters || {}),
      status: JSON.stringify(get(filters, 'status', [])),
      sort,
    });
    await handleApiFileDownloads(
      'get',
      `${process.env.REACT_APP_HOST_API}company/${companyId}/favr-values/export?${queryParams}`,
    );
  },

  /**
   * Fetch FAVR compliance report for a company
   * @param {string} companyId - ID of the company
   * @param {string} sort - Sort parameter for the report
   * @param {number} pageNumber - Page number for pagination
   * @param {number} pageSize - Number of items per page
   * @returns {Promise<object>} Response data containing compliance report
   */
  fetchFavrCompliancesReport: async (companyId, sort, pageNumber, pageSize) => {
    const queryParams = cleanQueryParams({ sort, range: formatQueryRange(pageNumber, pageSize) });
    const response = await handleApiCalls(
      'get',
      `${process.env.REACT_APP_HOST_API}company/${companyId}/favr-compliances/forms-status?${queryParams}`,
    );

    return response.data;
  },

  /**
   * Export FAVR compliance report for a company
   * @param {string} companyId - ID of the company
   * @param {string} sort - Sort parameter for the export
   * @param {number} pageNumber - Page number for pagination
   * @param {number} pageSize - Number of items per page
   * @returns {Promise<object>} Response data containing exported report
   */
  exportFavrCompliancesReport: async (companyId, sort, pageNumber, pageSize) => {
    const queryParams = cleanQueryParams({ sort, range: formatQueryRange(pageNumber, pageSize) });
    await handleApiFileDownloads(
      'get',
      `${process.env.REACT_APP_HOST_API}company/${companyId}/favr-compliances/forms-status/export?${queryParams}`,
    );
  },
});
