import { kebabCase, pick } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import {
  handleApiCalls,
  handleApiErrors,
  performApiCallIfCompanySubIsActive,
} from '../../../api/axiosInstance';
import CompanyForm from '../../../components/CompanyForm';
import Spinner from '../../../components/Spinner';
import Toast from '../../../components/Toast';
import { INTERNAL_LINKS, USER_REG_STATUS } from '../../../enum';
import {
  emitFetchTimezones,
  emitUpdateGlobalBasename,
  saveCompany,
} from '../../../stores/actions/common';
import { emitNavigateTo } from '../../../stores/actions/router';
import { formatPageTitle } from '../../../utils/common';
import { hasCompanyAdminRole } from '../../../utils/roles';
import { selectStoreCurrentAuthUser } from '../../../utils/storeSelectors';
import classNames from './style.module.scss';

const CompanyInfoPageForm = props => {
  const { t } = props;
  const authUser = useSelector(selectStoreCurrentAuthUser);
  const { currentCompany, supportedCountries, timezones } = useSelector(state => state.common);
  const regStatus = useSelector(state => state.user.regStatus);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(emitFetchTimezones());
    // eslint-disable-next-line
  }, []);

  const updateCompany = async values => {
    setLoading(true);

    try {
      const result = await performApiCallIfCompanySubIsActive(
        'put',
        `${process.env.REACT_APP_HOST_API}company/${currentCompany._id}`,
        {
          name: values.name,
          country: values.country,
          city: values.city,
          state: values.state,
          postalCode: values.postalCode,
          streetOne: values.streetOne,
          ...(values.streetTwo ? { streetTwo: values.streetTwo } : {}),
          currencyUnit: values.currencyUnit,
          numEmployee: values.numEmployee,
          phone: values.phone,
          preferredTimezone: values.preferredTimezone,
          timeFormat: values.timeFormat,
        },
      );

      moment.tz.setDefault(values.preferredTimezone);

      // if user has completed this step before
      // skip regStatus update
      await dispatch(
        saveCompany(
          pick(result.data.data, [
            'address',
            'currencyUnit',
            'name',
            'numEmployee',
            'phone',
            'preferredTimezone',
          ]),
        ),
      );

      if (regStatus < USER_REG_STATUS.PRICES || !regStatus) {
        try {
          await handleApiCalls(
            'patch',
            `${process.env.REACT_APP_HOST_API}company/${currentCompany._id}/update-registration-status`,
            {
              regStatus: USER_REG_STATUS.PRICES,
            },
          );
          setLoading(false);
        } catch (error) {
          setLoading(false);
          handleApiErrors(error.response);
          return;
        }
      }
      Toast({
        type: 'success',
        message: t('companyUpdateSuccess'),
      });

      if (hasCompanyAdminRole(authUser)) {
        await dispatch(emitUpdateGlobalBasename(kebabCase(result.data.data.name)));
        await dispatch(emitNavigateTo(INTERNAL_LINKS.COMPANY));
      }
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('companyUpdateError'),
          description: t('companyUpdateErrorDescription'),
        });
      });
    }
    setLoading(false);
  };

  return (
    <div className={classNames.companyPage}>
      <Helmet>
        <title>{formatPageTitle('Company Information')}</title>
      </Helmet>

      <div className="company-page">
        <Spinner spinning={loading}>
          <CompanyForm
            t={t}
            editableName
            updateCompany={updateCompany}
            currentCompany={currentCompany}
            history={props.history}
            timezones={timezones}
            countries={supportedCountries}
          />
        </Spinner>
      </div>
    </div>
  );
};

export default CompanyInfoPageForm;
